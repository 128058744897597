<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left" style="text-transform: capitalize;">{{title}}</h2>
    </div>
      <div class="row">
        <div class="col-6 offset-6">
          <b-input-group
            class="mb-3"
            size="md"
            
          >
          
            <b-form-input placeholder="Buscar Cliente"></b-form-input>
            <b-input-group-append>
              <button class="btn btn-primary-input" variant="success"><b-icon icon="search" ></b-icon></button>
            </b-input-group-append>
          </b-input-group>
          <p class="text-right mt-4 mb-0">
            <a :href="$store.state.url + 'clientes/getExcel'">Descargar en formato de Excel (.xlsx)</a>
          </p>
        </div>
      </div>
    <!-- <label class="form-control pointer">
      Selecciona un archivo
      <input type="file" style="display: none;" accept=".jpg" @change="onFileSelected($event)" />
    </label> -->

    
    <!-- <button @click="submitFile()" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button> -->


    <!-- <div class="container-fluid p-0">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Tarjeta</button>
    </div> -->
    <div class="my-4 mb-2">
    </div>
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(fecha_inicio)="data">
          <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : '' }}</span>
      </template>
      <template #cell(fecha_fin)="data">
          <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : '' }}</span>
      </template>
      <template #cell(action)="data">
          <b-button @click="sendMailAndSave(data.item)" v-b-tooltip.hover title="Aprobar usuario y enviar correo" v-if="data.item.estado ==0 || data.item.estado == null" class="btn-white-outline-small mx-1"><i class="fa fa-check"></i></b-button>
          <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
          <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>

      </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
      </div>
    </div>


    <!-- show details and edit -->    
    
  <b-modal ref="modal-item2" hide-footer >
    <template #modal-title>
      Clientes
    </template>

    <template>
      <form @submit.prevent="saveData()" style="padding:10px">
        <div class="row">
          <div class="col-12">
            <label for="">Nombre</label>
            <input required class="form-control" v-model="row.nombre" type="text" name="" id="">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="">Email</label>
            <input required class="form-control" v-model="row.email" type="text" name="" id="">
          </div>
       </div>
        <div class="row">
          <div class="col-6">
            <label for="">Telefono</label>
            <input  class="form-control" v-model="row.telefono" type="text" name="" id="">
          </div>
          <div class="col-6">
            <label for="">Agencia</label>
            <input required class="form-control" v-model="row.agencia" type="text" name="" id="">
          </div>
          <div class="col-6">
            <label for="">Contraseña</label>
            <input required class="form-control" v-model="row.clave" type="text" name="" id="">
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="">Estado</label>
            <!-- <input class="form-control" v-model="row.urlimagen" type="text" name="" id=""> -->
              <b-form-select value-field="id" text-field="nombre" :options="estados" required v-model="row.estado" class="mb-3 form-control">
              </b-form-select>            
          </div>
        </div>           

        <div class="row mt-4">
          <div class="col-12">
            <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>            
            <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
          </div>
        </div>
      </form>
    </template>
  </b-modal>  
  </div>
</template>

<script>
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      selected: null,
        categorias: [
          { id: 1, text: 'Categoría' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
        subcategorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,

      table: "clientes",
      modalText: 'Nueva Ficha',
      title: 'Clientes',
      items: [],

      table_list: [],
      row:{},
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'nombre', label: 'Nombre', sortable: true, class: 'text-center' },
          { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
          { key: 'agencia', label: 'Agencia', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filtered: null,
      estados:[
        {id:0, nombre: 'Rechazado'},
        {id:1, nombre: 'Aprobado'}

      ]
    };
  },
  async mounted() {
    this.$isLoading(true)
    await this.getData();
    // await this.getSubcategories();
    this.$isLoading(false)

  },
  methods: {
    async sendMailAndSave(row) {
      console.log('save and sendmail', row)
      try {
        let req
        let data = {
          estado : 1
        }
        req = await this.$store.dispatch("put", {
          path: this.table + '/modify/'+ row.id,
          data: data
        });

        req = await this.$store.dispatch("post", {
          path: 'enviarCorreo/aprobado/',
          data: {email: row.email}
        });
        
        console.log(req)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        //alert(error);
      }
    },    
    async onFileSelected(name) {
      try {
        const file = event.target.files[0];
        let filename = Math.floor(Math.random() * 9999999999999 + 1);

        let formData = new FormData();
        formData.append("name", "my-picture");
        formData.append("file", file);
        filename = name+'_'+filename+ '.jpg';
        console.log(filename)
        let url = this.$store.state.url + 'subirarchivos2/'+filename;
        let opts = {
          method: "POST",
          body: formData,
        };
        let req = await fetch(url, opts);
        if (req.ok) {
          this.$swal.fire({
            title: "Archivo subido correctamente!",
            icon: "success",
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    },   
    async submitFile(){
      const file = this.$refs.file.files[0];
      // this.file //event.target.files[0];
      let formData = new FormData();
      formData.append("name", "my-picture");
      formData.append("file", file);
      console.log('file', filename)
      let filename = file.name + Math.floor(Math.random() * 999999 + 1);
      filename = filename.toLowerCase();

      let url = this.$store.state.url + 'subirarchivos2/'+filename;
      let opts = {
        method: "POST",
        body: formData,
      };
      let req = await fetch(url, opts);
      if (req.ok) {
        this.$swal.fire({
          title: "File uploaded",
          icon: "info",
        });
        //   this.$refs["modal-1"].hide();
      }
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getSubcategories() {
      try {
        let res = await this.$store.dispatch("get", { path: 'subcategorias/getAllByCategory/' + this.categoria_id});
        console.log('------------->',res)
        if(res.length>0){
          this.subcategorias = res
        }
        else{
          this.subcategorias = []
        }
      } catch (error) {
        this.subcategorias = []
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error:" + error,
        });
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + '/getAll'});
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.table_list = []
        this.totalRows = this.table_list.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async saveData() {
      console.log('save', this.row)
      try {
        let req
        let data = this.row
        let id = this.row.id
        if(this.row.id){
          delete data.id
          req = await this.$store.dispatch("put", {
            path: this.table + '/modify/'+ id,
            data: data
          });
        }
        else{
          req = await this.$store.dispatch("post", {
            path: this.table + '/new',
            data: this.row
          });
        }
        console.log(req)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        //alert(error);
      }
    },
    ///////////////


    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }

        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        //alert(error);
      }
    },
  },
};
</script>

<style>

 

</style>